import { useAppDeps, useSDK } from '@features/app-deps-provider'
import { useAuthState } from '@features/app-hooks'
import { documentUploadsModule, uploadDocument } from '@features/document-uploads'
import { Link } from '@features/link'
import { clientFolderShareableLink, routes } from '@features/routing'
import { AnnotationLayer, annotationLayerModule, Page } from '@features/st-annotation-layer'
import { TOOLS } from '@features/st-doc-viewer/module'
import { PDFDocToolbar } from '@features/st-doc-viewer/pdf-doc-toolbar'
import { STPDFViewer } from '@features/st-pdf-viewer'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { useProcess, useProcessState } from '@st/redux'
import { FolderSummary, STDocument } from '@st/sdk'
import {
  ArrowRightIcon,
  Button,
  ContextMenuItem,
  DownloadIcon,
  EyeIcon,
  FullScreenDropZoneBarrier,
  Modal,
  PrimaryContextMenu,
  SendIconLarge,
  useFileDrop
} from '@st/theme'
import { cuid } from '@st/util/cuid'
import { parseMimeType } from '@st/util/mime'
import { cn } from '@util/cn'
import { StateDiff, StateLoad } from '@util/remote-socket'
import { useEffect, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import { STAnnotationsPanel } from './st-annotations-panel'
import { STBookmarksSidebar } from './st-bookmarks-sidebar'
import { stDownloadDocumentHandler } from './st-download-document-handler'
import { STDownloadFolderDialog } from './st-download-folder-dialog'
import {
  formatFolderEntityName,
  selSelectedDoc,
  STFolderMessage,
  stFolderModule
} from './st-folder-module'
import { StFolderViewerDebug } from './st-folder-viewer-debug'
import { STFormationChecklistViewer } from './st-formation-checklist-viewer'
import { STFormationViewer } from './st-formation-viewer'
import { STSendQuestionnaireDialog } from './st-send-questionnaire-dialog'
import { useFeatureEnabled } from '@util/feature-flags'
import { DocFieldAnnotation } from '@features/st-doc-viewer/field-types'
import { useDevMode } from '@st/react-util/dev-mode'

type FolderAction =
  | { type: 'openSendQuestionnaireDialog' }
  | { type: 'downloadFolderDialog' }
  | { type: 'copyWorkpaperLink' }
  | { type: 'copyQuestionnaireLink' }
  | { type: 'previewQuestionnaire' }
  | { type: 'uploadDocuments'; dataTransfer: DataTransfer }

type Props = {
  folderId: string
  selectedDocId?: string
  onSelect?: (doc: STDocument) => void
  debug?: boolean
}
export function STFolderViewer({ folderId, debug }: Props) {
  const sdk = useSDK()
  const { socket } = useAppDeps()
  const { user } = useAuthState()

  const documentUploads = useProcess(documentUploadsModule)
  const stFolder = useProcess(stFolderModule, { currentUserId: user!.id })
  const platform = useProcess(platformModule)

  const folderLoaded = useProcessState(stFolderModule, (s) => s.folderState?.folder != undefined)
  const organizationSlug = useProcessState(stFolderModule, (s) => s.folderState?.organization.slug)
  const selectedDoc = useProcessState(stFolderModule, selSelectedDoc)
  const entityName = useProcessState(stFolderModule, (s) =>
    formatFolderEntityName(s.folderState?.folder.entities)
  )
  const questionnaireStatus = useProcessState(
    stFolderModule,
    (s) => s.folderState?.folder.questionnaireStatus ?? 'not_seen_yet'
  )

  const [action, setAction] = useState<FolderAction | undefined>()

  useEffect(() => {
    const unsub = socket.subscribe(`folder:${folderId}`)
    socket.on(`folder:${folderId}`, 'state:load', (e: StateLoad) => {
      stFolder.send({ type: 'stateLoad', vsn: e.vsn, state: e.state })
    })
    socket.on(`folder:${folderId}`, 'state:diff', (e: StateDiff) => {
      stFolder.send({ type: 'stateDiff', fromVsn: e.fromVsn, toVsn: e.toVsn, diff: e.diff })
    })

    return () => unsub()
  }, [socket, folderId, stFolder])

  useEffect(() => {
    if (folderLoaded) {
      // if folder isn't loaded we don't conside it viewed
      sdk.send({ type: 'folders/trackFolderViewed', folderId: folderId })
    }
  }, [sdk, folderId, folderLoaded])

  if (debug) {
    return <StFolderViewerDebug />
  }

  return (
    <STFolderViewerInner
      entityName={entityName}
      action={action}
      folderId={folderId}
      questionnaireStatus={questionnaireStatus}
      selectedDoc={selectedDoc}
      loading={!folderLoaded}
      onAction={(action) => {
        switch (action?.type) {
          case 'copyWorkpaperLink':
            platform.send({ type: 'copyToClipboard', text: window.location.href })
            platform.send({
              type: 'showSnackbar',
              message: 'Copied workpaper link to clipboard'
            })
            break
          case 'copyQuestionnaireLink':
            platform.send({
              type: 'copyToClipboard',
              text: clientFolderShareableLink({
                organizationSlug: organizationSlug!,
                folderId: folderId
              })
            })
            platform.send({
              type: 'showSnackbar',
              message: 'Copied questionnaire link to clipboard'
            })
            break
          case 'previewQuestionnaire':
            window.open(
              routes.toPath({
                name: 'organization_preview_questionnaire',
                organizationSlug: organizationSlug,
                folderId: folderId
              })
            )
            break
          case 'uploadDocuments':
            for (const file of action.dataTransfer.files) {
              documentUploads.send(
                uploadDocument({
                  uploadId: cuid(),
                  file,
                  folderId: folderId
                })
              )
            }
            break
          default:
            setAction(action)
        }
      }}
      send={stFolder.send}
    />
  )
}

function STFolderViewerInner({
  folderId,
  entityName,
  selectedDoc,
  questionnaireStatus,
  action,
  loading,
  onAction,
  send
}: {
  folderId: string
  entityName: string | undefined
  selectedDoc: STDocument | undefined
  questionnaireStatus: FolderSummary['questionnaireStatus']
  action: FolderAction | undefined
  loading: boolean
  onAction: (action: FolderAction | undefined) => void
  send: (message: STFolderMessage) => void
}) {
  function onDrop(dataTransfer: DataTransfer) {
    onAction({ type: 'uploadDocuments', dataTransfer: dataTransfer })
  }
  const { isDragging, dropHandlers } = useFileDrop(onDrop)

  return (
    <div
      className="grid h-full w-full grid-rows-[50px_auto] overflow-hidden"
      style={{ minWidth: '1200px' }}
      {...dropHandlers}
    >
      {isDragging && <FullScreenDropZoneBarrier />}
      <div className="bg-white-200 flex flex-row items-center justify-between border-b border-stone-200 px-5 py-3">
        {/* Breadcrumb */}
        <div className="flex flex-row items-center gap-2">
          <Link href={{ name: 'folder_list' }} className="text-lg text-gray-600">
            Workpapers
          </Link>
          {entityName ? <ArrowRightIcon className="h-4 w-4 text-gray-500" /> : null}
          {entityName ? (
            <span className="text-lg font-bold text-gray-900">{`${entityName}'s Workpaper`}</span>
          ) : null}
        </div>

        <div className="flex flex-row gap-2">
          <Button
            variant="subtle"
            leadingIcon={<DownloadIcon className="h-4 w-4" />}
            onClick={() => onAction({ type: 'downloadFolderDialog' })}
            disabled={loading}
          >
            Download Workpaper
          </Button>

          <Button
            variant="subtle"
            leadingIcon={<EyeIcon className="h-4 w-4" />}
            onClick={() => onAction({ type: 'previewQuestionnaire' })}
            disabled={loading}
          >
            Preview Questionnaire
          </Button>

          <Button
            variant="default"
            leadingIcon={<SendIconLarge className="h-4 w-4" />}
            onClick={() => onAction({ type: 'openSendQuestionnaireDialog' })}
            disabled={loading}
          >
            Send Questionnaire
          </Button>

          <PrimaryContextMenu>
            {questionnaireStatus == 'submitted' && (
              <ContextMenuItem
                onClick={() =>
                  send({
                    type: 'request',
                    request: { type: 'folders/unlockQuestionnaire', folderId: folderId }
                  })
                }
              >
                Unlock questionnaire
              </ContextMenuItem>
            )}

            <ContextMenuItem onClick={() => onAction({ type: 'copyQuestionnaireLink' })}>
              Copy client questionnaire link
            </ContextMenuItem>

            <ContextMenuItem onClick={() => onAction({ type: 'copyWorkpaperLink' })}>
              Copy firm workpaper link
            </ContextMenuItem>
          </PrimaryContextMenu>
        </div>
      </div>
      <div className="grid h-full w-full grid-cols-[300px_auto] overflow-hidden">
        <STBookmarksSidebar />

        {selectedDoc ? (
          <STDocViewer key={selectedDoc.id} folderId={folderId} doc={selectedDoc} />
        ) : (
          <></>
        )}
      </div>

      <Modal isOpen={action != undefined}>
        {match(action)
          .with({ type: 'openSendQuestionnaireDialog' }, () => (
            <STSendQuestionnaireDialog folderId={folderId} onClose={() => onAction(undefined)} />
          ))
          .with({ type: 'downloadFolderDialog' }, () => (
            <STDownloadFolderDialog folderId={folderId} onClose={() => onAction(undefined)} />
          ))
          .otherwise(() => null)}
      </Modal>
    </div>
  )
}

function STDocViewer({ doc, folderId }: { doc: STDocument; folderId: string }) {
  const mime = parseMimeType(doc.mimeType)

  return match(mime)
    .with({ type: 'application', subtype: 'formation' }, () => {
      return <STFormationViewer doc={doc} />
    })
    .with({ type: 'application', subtype: 'formation-checklist' }, () => {
      return <STFormationChecklistViewer />
    })
    .otherwise(() => {
      if (doc.processingStatus == 'processing') {
        return <DocumentSpinner caption="Processing..." />
      } else if (getDocPDFPath(doc)) {
        return <STPDFDocViewer doc={doc} />
      } else {
        return <DownloadNonViewableFile folderId={folderId} doc={doc} />
      }
    })
}

function DownloadNonViewableFile({ folderId, doc }: { folderId: string; doc: STDocument }) {
  const { sdk } = useAppDeps()
  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-stone-200">
      <div className="flex h-1/4 w-1/4 flex-col items-center justify-center rounded-md bg-white p-4 shadow-md">
        <svg className="h-16 w-16" viewBox="0 0 73 72" fill="none">
          <rect x="0.5" width="72" height="72" rx="36" fill="#111536" fillOpacity="0.4" />
          <path
            d="M44.0306 31.7194L38.7806 26.4694C38.7109 26.3997 38.6282 26.3445 38.5371 26.3069C38.4461 26.2693 38.3485 26.2499 38.25 26.25H29.25C28.8522 26.25 28.4706 26.408 28.1893 26.6893C27.908 26.9706 27.75 27.3522 27.75 27.75V44.25C27.75 44.6478 27.908 45.0294 28.1893 45.3107C28.4706 45.592 28.8522 45.75 29.25 45.75H42.75C43.1478 45.75 43.5294 45.592 43.8107 45.3107C44.092 45.0294 44.25 44.6478 44.25 44.25V32.25C44.2501 32.1515 44.2307 32.0539 44.1931 31.9629C44.1555 31.8718 44.1003 31.7891 44.0306 31.7194ZM39 28.8103L41.6897 31.5H39V28.8103ZM42.75 44.25H29.25V27.75H37.5V32.25C37.5 32.4489 37.579 32.6397 37.7197 32.7803C37.8603 32.921 38.0511 33 38.25 33H42.75V44.25Z"
            fill="white"
          />
        </svg>

        <div className="mb-4 mt-4 break-all text-center font-semibold text-gray-700">
          {doc.name}
        </div>

        <Button
          variant="primary"
          leadingIcon={<DownloadIcon className="h-4 w-4" />}
          onClick={() => {
            stDownloadDocumentHandler(doc, {
              sdk: sdk,
              folderId: folderId,
              format: 'source'
            })
          }}
        >
          Download
        </Button>
      </div>
    </div>
  )
}

function DocumentSpinner({ caption }: { caption?: string }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-6 bg-stone-200">
      <div role="status">
        <svg
          aria-hidden="true"
          className="h-16 w-16 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
          viewBox="0 0 100 101"
          fill="none"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
      <div className="text-center text-gray-700">{caption ?? ''}</div>
    </div>
  )
}

function STPDFDocViewer({ doc }: { doc: STDocument }) {
  const isDocumentAIFieldsEnabled = useFeatureEnabled('document-ai-fields')
  const isDevMode = useDevMode()

  const fields = useMemo(() => {
    return doc.fields.map((f) => {
      return {
        id: f.key,
        page: f.page,
        bounds: f.bounds!,
        time: 'now',
        type: 'field',
        body: f.value as any,
        color: 'red',
        border: {
          color: 'red',
          width: 1
        }
      } satisfies DocFieldAnnotation
    })
  }, [doc])

  const annotationLayer = useProcess(annotationLayerModule, {
    tools: TOOLS,
    annots: []
  })

  useEffect(() => {
    if (isDocumentAIFieldsEnabled) {
      annotationLayer.send({ type: 'load', annots: fields })
    }
  }, [fields])

  const sidebarType = useProcessState(annotationLayerModule, (s) => s.sidebarType)
  const docPDFPath = getDocPDFPath(doc)

  useEffect(() => {
    if (typeof window == 'undefined') return
    // PDFTron is unaware of the sidebar opening/closing which affects the overall width of
    // the PDF viewer's available width
    // but it does respond to the resize event
    // By artifically triggering the resize event, PDFTron is forced to update its pdf viewer to render
    // within the new dimensions of its container div
    window.dispatchEvent(new Event('resize'))
  }, [sidebarType])

  return (
    <div className="grid grid-rows-[50px_auto] overflow-hidden">
      <PDFDocToolbar statusBar={isDevMode && <DocDebugStatusBar doc={doc} />} />
      <div
        className={cn(
          'grid overflow-hidden',
          sidebarType ? 'grid-cols-[auto_300px]' : 'grid-cols-[auto]'
        )}
      >
        {docPDFPath && <STPDFViewer src={docPDFPath} PageOverlay={AnnotationPageOverlay} />}
        {sidebarType && <STAnnotationsPanel />}
      </div>
    </div>
  )
}

function getDocPDFPath(doc: STDocument) {
  if (doc.urls.pdf) {
    return doc.urls.pdf
  }
  // Fall back to the source path if the pdfPath is not available
  // and the mime type is pdf (which means we can render the unconverted source path as pdf)
  else if (doc.mimeType == 'application/pdf' && doc.urls.sourceInline) {
    return doc.urls.sourceInline
  }
  return undefined
}

// AI annotations  layer
function AnnotationPageOverlay({ page, zoomLevel }: { page: Page; zoomLevel: number }) {
  const annotationLayer = useProcess(annotationLayerModule)
  const state = useProcessState(annotationLayerModule)

  return (
    <AnnotationLayer
      size={page.size}
      zoomLevel={zoomLevel}
      pageIndex={page.index}
      state={state}
      send={annotationLayer.send}
    />
  )
}

function DocDebugStatusBar({ doc }: { doc: STDocument }) {
  return <div className="text-sm text-gray-500">doc id = {doc.id}</div>
}
